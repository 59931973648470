import { Form, Formik } from 'formik';
import FormikRadio from '../../../../components/Form/Formik/FormikRadio';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import { FormikSelectInput } from '../../../../components/Form/Formik/FormikSelect';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import React, { useEffect, useState } from 'react';
import { useClassNames } from '../../../../hooks/useClassNames';
import { getAvailableFeatureLimitations, updateCustomerLicenseApi } from '../../../../api/license';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import * as Yup from 'yup';
import { initFormValues, initTabs, subscriptionModel } from '../consts/License';
import { ExclamationIcon } from '@heroicons/react/outline';
import Restrictions from './Restrictions';
import Features from './Features';
import GlobalFeatures from './GlobalFeatures';
import LicenseNotifications from './LicenseNotifications';
import { getByCurrency, toUsageThresholds } from '../services';
import { Button } from '../../../../components';

const CustomerLicense = ({ selectedCustomer, customer, packages, products }) => {
  const [tabs, setTabs] = useState(initTabs);
  const [formValues, setFormValues] = useState(initFormValues);
  const [license, setLicense] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isDowngraded, setIsDowngraded] = useState(false);
  const [isGoingToDowngrade, setIsGoingToDowngrade] = useState(false);
  const [availableLimitations, setAvailableLimitations] = useState(null);
  const [isNewLicenseMode, setIsNewLicenseMode] = useState(false);

  const { classNames } = useClassNames();
  const updateCustomerLicenseAction = useToastAction();
  const availableLimitationsAction = useToastAction();

  const fetchAvailableLimitations = () =>
    availableLimitationsAction.execute(async () => {
      const response = await getAvailableFeatureLimitations();
      setAvailableLimitations(response);
    }, '');

  const updateCustomerLicense = (requestData) => {
    updateCustomerLicenseAction.execute(
      async () => {
        await updateCustomerLicenseApi(selectedCustomer.customerId, requestData);

        setLicense({
          ...license,
          ...requestData
        });
        setIsDowngraded(requestData.tierLevel < customer.license.tierLevel);
        setIsGoingToDowngrade(false);
      },
      'Failed',
      'Save successful'
    );
  };

  const validationSchema = Yup.object().shape({
    maxUsers: Yup.string().when('licenseType', {
      is: subscriptionModel.MonthlyPerUser.toString(),
      then: Yup.string()
        .test('Digits only', 'The field should have digits only', (value) => /^\d+$/.test(value))
        .test('Min number of users', 'Minimum number is 1', (value) => parseInt(value) > 0)
    }),
    globalFeatures: Yup.array().of(
      Yup.object().shape({
        usageThresholds: Yup.array().of(
          Yup.object().shape({
            lower: Yup.string().required('Required'),
            price: Yup.string().required('Required')
          })
        )
      })
    ),
    plansPrices: Yup.array().of(
      Yup.object().shape({
          price: Yup.number().typeError('Must be a number').required('Required').min(0, 'Must be a positive number')
      })
    )
  });

  const mapToRequestProductData = (products, formValues) => {
    return products
      .filter((product) => formValues.products.some(
        (formProductId) => formProductId === product.id)
      )
      .map((product) => {
        const foundFormProduct = formValues.productsFeatures
          .find((prodFeat) => prodFeat.id === product.id);

        return {
          ...product,
          features: [
            ...product.features.map((feature) => {
              const foundProductFeatureForm = foundFormProduct.features.find(
                (el) => el.featureCode === feature.featureCode
              );

              return {
                ...feature,
                productFeaturePrice: {
                  ...feature.productFeaturePrice,
                  usageThresholds: toUsageThresholds(
                    foundProductFeatureForm?.usageThresholds,
                    selectedCustomer.currency
                  )
                }
              };
            })
          ]
        };
      });
  };

  const handleOnTabItemClick = (tab, e) => {
    e.preventDefault();

    setTabs((state) =>
      state.map((s) => {
        if (s.name === tab.name) {
          s.current = true;
        } else {
          s.current = false;
        }

        return s;
      })
    );
  };

  const validateLimitations = (limitations) => {
    const result = {
      isValid: true,
      errors: []
    };

    for (let prop in limitations) {
      if (
        limitations.hasOwnProperty(prop) &&
        (limitations[prop].length === 0 || limitations[prop] == null)
      ) {
        result.errors.push(`limitations.${prop}`);
        result.isValid = false;
      }
    }

    return result;
  };

  const mapToGlobalFeaturesRequest = (globalFeatures, formValues) => {
    return globalFeatures
      .filter((globalFeature) =>
        formValues.some((formValue) => formValue.featureCode === globalFeature.featureCode)
      )
      .map((globalFeature) => {
        const value = formValues.find(
          (formValue) => formValue.featureCode === globalFeature.featureCode
        );

        return {
          ...globalFeature,
          productFeaturePrice: {
            ...globalFeature.productFeaturePrice,
            usageThresholds: toUsageThresholds(
              value?.usageThresholds,
              selectedCustomer.currency
            )
          }
        };
      });
  };

  const handleOnSubmit = async (values, actions) => {
    const validateLimitationsResult = validateLimitations(values.limitations);

    if (validateLimitationsResult.isValid) {
      const requestData = {
        planName: values.planName,
        prePriced: values.plan.prePriced,
        subscriptionModel: parseInt(values.licenseType),
        maxUsers: parseInt(values.maxUsers),
        planPrices: values.plansPrices
          .filter((el) => el.name === values.planName)
          .map((el) => ({
            currency: el.currency,
            price: el.price
          })),
        products: mapToRequestProductData(products, values),
        planProductLimitations: {
          productCategoryUsageLimitations: values.limitations
        },
        globalFeatures: mapToGlobalFeaturesRequest(
          selectedPackage.globalFeatures,
          values.globalFeatures
        ),
        tierLevel: values.plan.tierLevel,
        requirePaymentRegistration: values.plan.requirePaymentRegistration
      };

      await updateCustomerLicense(requestData);

      setFormValues(values);
    } else {
      for (let i = 0; i < validateLimitationsResult.errors.length; i++) {
        actions.setFieldError(validateLimitationsResult.errors[i], 'Required');
      }
    }

    actions.setSubmitting(false);
  };

  const handleOnPlanChange = (value, formikProps) => {
    if (!isNewLicenseMode) {
      setIsNewLicenseMode(true);
    }

    const selectedPackage = packages.find((el) => {
      return el.planName === value;
    });

    if (selectedPackage) {
      setSelectedPackage(() => packages.find((p) => p.planName === selectedPackage.planName));

      setFormValues({
        ...formikProps.values,
        planName: value,
        plan: {
          ...formikProps.values.plan,
          tierLevel: selectedPackage.tierLevel,
          requirePaymentRegistration: selectedPackage.requirePaymentRegistration,
          prePriced: selectedPackage.prePriced
        },
        limitations: selectedPackage.planProductLimitations.productCategoryUsageLimitations,
        products: license
          ? license.products.map((product) => product.id)
          : selectedPackage.products.map((product) => product.id)
      });

      setIsGoingToDowngrade(selectedPackage.tierLevel < license.tierLevel);
    }
  };

  const mapperToFormData = (data) => {

    let returnFormData = 
    {
      planName: data.planName,
      plan: {
        ...formValues.plan,
        tierLevel: data.tierLevel,
        requirePaymentRegistration: data.requirePaymentRegistration,
        prePriced: data.prePriced
      },
      price: getByCurrency(
        data.planPrices,
        selectedCustomer.currency,
        'currency',
        'price'
      ).toString(),
      licenseType: data.subscriptionModel,
      maxUsers: data.maxUsers || '',
      products: data.products.map((product) => product.id),
      productsFeatures: products.map((product) => ({
        id: product.id,
        name: product.name,
        features: product.features.map((feature) => ({
          featureCode: feature.featureCode,
          usageThresholds: feature.productFeaturePrice.usageThresholds?.map((usageThreshold) => ({
            lower: usageThreshold.lower,
            upper: usageThreshold.upper,
            price: getByCurrency(
              usageThreshold.usageCategories[0].usagePrices,
              selectedCustomer.currency,
              'usagePriceCurrency',
              'usagePrice'
            ).toString()
          }))
        }))
      })),
      limitations: data.planProductLimitations.productCategoryUsageLimitations,
      globalFeatures: data.globalFeatures.map((globalFeature) => ({
        name: globalFeature.name,
        featureCode: globalFeature.featureCode,
        usageThresholds: globalFeature.productFeaturePrice.usageThresholds.map((usageThreshold) => 
        ({
          lower: usageThreshold.lower,
          upper: usageThreshold.upper,
          price: getByCurrency(
            usageThreshold.usageCategories[0].usagePrices,
            selectedCustomer.currency,
            'usagePriceCurrency',
            'usagePrice'
          ).toString()
        }))
      })),
      plansPrices: data.packages.map((p) => {
        let prices = p.planPrices;

        if (p.planName === data.planName) {
          prices = data.planPrices;
        }

        return {
          name: p.planName,
          currency: selectedCustomer.currency,
          price: getByCurrency(
            prices,
            selectedCustomer.currency,
            'currency',
            'price'
          ).toString()
        };
      })
    };

    data.products.map((product) => {
      returnFormData
      .productsFeatures[returnFormData.productsFeatures
        .findIndex(x => x.id === product.id)] = {
        id: product.id,
        name: product.name,
        features: product.features.map((feature) => ({
          featureCode: feature.featureCode,
          usageThresholds: feature.productFeaturePrice.usageThresholds?.map((usageThreshold) => ({
            lower: usageThreshold.lower,
            upper: usageThreshold.upper,
            price: getByCurrency(
              usageThreshold.usageCategories[0].usagePrices,
              selectedCustomer.currency,
              'usagePriceCurrency',
              'usagePrice'
            ).toString()
          }))
        }))
      }
    });

    return returnFormData;
  };

  const prepareFormValues = (customerLicense) => {
    const val = { ...customerLicense, packages };

    const mappedData = mapperToFormData(val);

    setFormValues({
      ...formValues,
      ...mappedData
    });
  };

  const handleOnFieldChange = () => {
    if (!isNewLicenseMode) {
      setIsNewLicenseMode(true);
    }
  };

  const copyJsonContentToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(license, undefined, 2));
  };

  const init = () => {
    if(selectedCustomer.country)
    {
      const productPackage = packages.find((p) => p.planName === customer.license.planName);
      setIsDowngraded(!!customer.nextLicense);
      setIsGoingToDowngrade(false);
      setIsNewLicenseMode(false);
      setLicense(customer.license);
      setSelectedPackage(productPackage);

      prepareFormValues(customer.license, productPackage);
    }
  };

  useEffect(() => {
    fetchAvailableLimitations();
  }, []);

  useEffect(() => {
    if (customer && availableLimitations) {
      init(customer);
    }
  }, [customer, availableLimitations]);

  return (
    <>
      <div className="border-b border-gray-200 pb-5 sm:pb-0">
        <div className="mb-4 pb-6 border-b border-gray-200">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{selectedCustomer.name}</h3>

          <dl className="mt-3 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Org</dt>
              <dd className="mt-1 text-sm text-gray-900">{selectedCustomer.orgNumber}</dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Active users</dt>
              <dd className="mt-1 text-sm text-gray-900">{customer.activeUsers}</dd>
            </div>
          </dl>
        </div>

        <nav className="-mb-px flex space-x-8 mt-4 sm:mt-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={(e) => handleOnTabItemClick(tab, e)}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>

      {tabs.find((tab) => tab.current).name === 'Settings' ? (
        <>
          {license && selectedCustomer.country && (
            <div className="mt-6">
              <LicenseNotifications
                isNewLicenseMode={isNewLicenseMode}
                isDowngraded={isDowngraded}
                isGoingToDowngrade={isGoingToDowngrade}
              />

              <Formik
                initialValues={formValues}
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
              >
                {(formikProps) => (
                  <Form>
                    <div className="space-y-8 divide-y divide-gray-200">
                      {packages && (
                        <div>
                          <label className="text-base font-medium text-gray-900">
                            Product Package
                          </label>
                          <div className="pt-6">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                              <div className="sm:col-span-3">
                                <FormikSelectInput
                                  label="License type"
                                  name="licenseType"
                                  options={[
                                    {
                                      name: 'Monthly',
                                      value: subscriptionModel.Monthly
                                    },
                                    {
                                      name: 'Monthly per user',
                                      value: subscriptionModel.MonthlyPerUser
                                    }
                                  ]}
                                  formikProps={formikProps}
                                  onChange={handleOnFieldChange}
                                />
                              </div>

                              <div className="sm:col-span-3">
                                {parseInt(formikProps.values.licenseType) ===
                                  subscriptionModel.MonthlyPerUser && (
                                  <FormikInput
                                    label="Number of users"
                                    name="maxUsers"
                                    formikProps={formikProps}
                                    onChange={handleOnFieldChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <fieldset className="mt-6">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                              {packages.map((p, index) => {
                                let radioLabel = p.planName;

                                if (license.tierLevel === p.tierLevel) {
                                  radioLabel = `${p.planName} (Current tier level)`;
                                }

                                return (
                                  <div key={p.id} className="sm:col-span-3 flex flex-col justify-between">
                                    <FormikRadio
                                      label={radioLabel}
                                      description={p.planDescription}
                                      name="planName"
                                      value={p.planName}
                                      formikProps={formikProps}
                                      onChange={(e) =>
                                        handleOnPlanChange(e.target.value, formikProps)
                                      }
                                    />

                                    <div className="mt-4">
                                      <FormikInput
                                        label={`Price ${
                                          parseInt(formikProps.values.licenseType) ===
                                          subscriptionModel.MonthlyPerUser
                                            ? '(per user)'
                                            : ''
                                        }`}
                                        name={`plansPrices.${index}.price`}
                                        formikProps={formikProps}
                                        prefix={selectedCustomer.currency}
                                        disabled={formikProps.values.planName !== p.planName}
                                        onChange={handleOnFieldChange}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </fieldset>
                        </div>
                      )}

                      <div className="pt-6">
                        <p className="text-base font-medium text-gray-900">Products</p>

                        <fieldset className="mt-4">
                          <legend className="text-sm font-medium text-gray-900">Included:</legend>

                          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                            {selectedPackage &&
                              selectedPackage.products
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((p) => (
                                  <div key={p.productCode}>
                                    <FormikCheckbox
                                      label={p.name}
                                      name="products"
                                      formikProps={formikProps}
                                      value={p.id}
                                      onClick={handleOnFieldChange}
                                    />
                                  </div>
                                ))}
                          </div>
                        </fieldset>

                        <fieldset className="mt-8">
                          <legend className="text-sm font-medium text-gray-900">Additional:</legend>

                          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                            {products
                              .filter((p) => {
                                return !selectedPackage.products.some((sp) => sp.id === p.id);
                              })
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((p) => (
                                <div key={p.productCode}>
                                  <FormikCheckbox
                                    label={p.name}
                                    name="products"
                                    formikProps={formikProps}
                                    value={p.id}
                                    onClick={handleOnFieldChange}
                                  />
                                </div>
                              ))}
                          </div>
                        </fieldset>
                      </div>

                      <div className="pt-6">
                        <p className="text-base font-medium text-gray-900">Restrictions</p>

                        {availableLimitations && (
                          <Restrictions
                            availableLimitations={availableLimitations}
                            planProductLimitations={selectedPackage.planProductLimitations}
                            formikProps={formikProps}
                            onChange={handleOnFieldChange}
                          />
                        )}
                      </div>

                      <div className="pt-6">
                        <p className="text-base font-medium text-gray-900">Global features</p>

                        <GlobalFeatures
                          formikProps={formikProps}
                          selectedPackage={selectedPackage}
                          currency={selectedCustomer.currency}
                          onChange={handleOnFieldChange}
                        />
                      </div>

                      <div className="pt-6">
                        <p className="text-base font-medium text-gray-900">Product features</p>

                        <Features
                          products={products}
                          formikProps={formikProps}
                          currency={selectedCustomer.currency}
                          onChange={handleOnFieldChange}
                        />
                      </div>

                      <div className="flex justify-end items-end pt-6">
                        <FormikSubmitButton
                          id="save"
                          text="Save"
                          formikProps={formikProps}
                          disabled={isDowngraded}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {!selectedCustomer.country && (
            <div className="mt-4 rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    Selected customer does not contain country assigned to it's account. 
                    It is required to have country assigned before manage customer license.
                    Please set customer country and then this form will be enabled.
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="mt-6 text-sm sm:grid sm:grid-cols-8">
          <div className={'sm:col-start-1 sm:col-end-8'}>
            <pre>{JSON.stringify(license, undefined, 2)}</pre>
          </div>
          <div className={'flex mt-5 sm:justify-end sm:mt-0 sm:col-start-8 sm:col-end-8'}>
            <Button variant={Button.variants.primary} onClick={copyJsonContentToClipboard}>
              Copy JSON
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerLicense;
